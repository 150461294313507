import { map, tap, startWith } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { IndexAliasService } from './../../_services/index-alias.service';
import { LanguageService } from './../../_services/language.service';
import { Component, OnInit } from '@angular/core';
import { FrontEndService } from 'app/_services/front-end.service';
import { ClientService } from 'app/_services/client.service';
import { ActiveAlias } from 'app/models/alias-list';
import { Client } from 'app/models/client';
import { FrontEnd } from 'app/models/front-end';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'fury-centro-preview',
  templateUrl: './centro-preview.component.html',
  styleUrls: ['./centro-preview.component.scss']
})
export class CentroPreviewComponent implements OnInit {

  aliasList: ActiveAlias[] = [];
  clientsList: Client[] = [];
  frontEndList: FrontEnd[] = [];

  filteredActiveAliases: Observable<ActiveAlias[]>;
  filteredFrontEnds: Observable<FrontEnd[]>;
  filteredClients: Observable<Client[]>;

  centroPreviewForm: FormGroup;

  resultUrl: string;
  sanitizedUrl: any;

  private activeAlias: AbstractControl;
  private frontEnd: AbstractControl;
  private client: AbstractControl;
  public useInactiveAlias: AbstractControl;

  public showWarningMessage = false;

  public allListAvailable = false;

  constructor(public languageService: LanguageService,
    private indexAliasService: IndexAliasService,
    private frontEndService: FrontEndService,
    private clientService: ClientService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.centroPreviewForm = this.fb.group({
      client: ['', Validators.required],
      alias: ['', Validators.required],
      frontend: ['', Validators.required],
      useInactiveAlias: [false]
    });
    
    this.recuperaDati();
  }

  private recuperaDati(): void {
    forkJoin(
      [this.indexAliasService.getAliasesShortList(),
      this.clientService.getClients(),
      this.frontEndService.getFrontEnds()]
    ).subscribe(([aliasResponse, clientResponse, frontEndResponse]) => {
      this.aliasList = aliasResponse.active;
      this.clientsList = clientResponse;
      this.frontEndList = frontEndResponse;

      this.allListAvailable = !!this.aliasList.length && !!this.clientsList.length && !!this.frontEndList.length;
      this.initForm();
    });
  }

  private initForm(): void {
    this.activeAlias = this.centroPreviewForm.get('alias');
    this.frontEnd = this.centroPreviewForm.get('frontend');
    this.client = this.centroPreviewForm.get('client');
    this.useInactiveAlias = this.centroPreviewForm.get('useInactiveAlias');

    this.checkAliasList();
    this.checkFrontEndList();
    this.checkClientsList();

    this.filteredActiveAliases = this.activeAlias.valueChanges.pipe(
      startWith(''),
      map(value => <string>value),
      tap(value => {
        // Se l'alias inserito è presente in lista allora tolgo gli errori
        if (!!this.getActiveAliasFromList(value)) {
          this.activeAlias.setErrors(null);
        } else {
          // Finchè c'è un valore lascio il campo con errore
          // else non c'è bisogno perché non essendo required quando
          // non c'è valore in automatico vengono tolti gli errori
          if (this.activeAlias.value) {
            this.activeAlias.setErrors({
              isNotAlias: true
            });
          }
        }
      }),
      map(value => this.filterActiveAliases(value))
    );

    this.filteredFrontEnds = this.frontEnd.valueChanges.pipe(
      startWith(''),
      map(value => <string>value),
      tap(value => {
        // Se il frontend inserito è presente in lista allora tolgo gli errori
        if (!!this.getFrontEndFromList(value)) {
          this.frontEnd.setErrors(null);
        } else {
          // Finchè c'è un valore lascio il campo con errore
          // else non c'è bisogno perché non essendo required quando
          // non c'è valore in automatico vengono tolti gli errori
          if (this.frontEnd.value) {
            this.frontEnd.setErrors({
              isNotFrontEnd: true
            });
          }
        }
      }),
      map(value => this.filterFrontEnd(value))
    );

    this.filteredClients = this.client.valueChanges.pipe(
      startWith(''),
      map(value => <string>value),
      tap(value => {
        // Se il this.client inserito è presente in lista allora tolgo gli errori
        if (!!this.getClientFromList(value)) {
          this.client.setErrors(null);
        } else {
          // Finchè c'è un valore lascio il campo con errore
          // else non c'è bisogno perché non essendo required quando
          // non c'è valore in automatico vengono tolti gli errori
          if (this.client.value) {
            this.client.setErrors({
              isNotFrontEnd: true
            });
          }
        }
      }),
      map(value => this.filterClients(value))
    );

    this.useInactiveAlias.valueChanges.subscribe(value => {
      this.indexAliasService.getAliasesShortList().subscribe(resp => {

        if (resp.inactive.length) {
          this.activeAlias.patchValue('');
          this.aliasList = value ? resp.active.concat(resp.inactive) : resp.active;

          if (this.activeAlias.disabled) {
            this.activeAlias.enable();
            this.showWarningMessage = false;
          }
        }

        this.checkAliasList();
      });
    });
  }

  /**
   * Controlla se ci sono elementi nella lista degli alias, altimenti disabilita il campo e
   * inserisce una frase placeholder.
   */
  private checkAliasList(): void {
    this.allListAvailable = !!this.aliasList.length && !!this.clientsList.length && !!this.frontEndList.length;

    if (!this.aliasList.length) {
      this.activeAlias.patchValue(this.languageService.getLabel('general.NessunValoreSelezionabile'),
        { emitEvent: false, onlySelf: true });
      this.activeAlias.disable();

      this.showWarningMessage = true;
    }
  }

  /**
   * Controlla se ci sono elementi nella lista dei front end, altimenti disabilita il campo e
   * inserisce una frase placeholder.
   */
  private checkFrontEndList(): void {
    if (!this.frontEndList.length) {
      this.frontEnd.patchValue(this.languageService.getLabel('general.NessunValoreSelezionabile'),
        { emitEvent: false, onlySelf: true });
      this.frontEnd.disable();

      this.showWarningMessage = true;
    }
  }

  /**
   * Controlla se ci sono elementi nella lista dei client, altimenti disabilita il campo e
   * inserisce una frase placeholder.
   */
  private checkClientsList(): void {
    if (!this.clientsList.length) {
      this.client.patchValue(this.languageService.getLabel('general.NessunValoreSelezionabile'),
        { emitEvent: false, onlySelf: true });
      this.client.disable();

      this.showWarningMessage = true;
    }

  }

  public generaPreview(): void {
    this.frontEndService.generaUrlPreview(this.client.value, this.activeAlias.value, this.frontEnd.value).subscribe(resp => {
      this.resultUrl = resp.url;
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(resp.url);
    });
  }

  /**
 * Ritorna l'alias se l'id inserito viene trovato in lista.
 * E' case insensitive
 * @param value id dell'alias da ricercare
 */
  private getActiveAliasFromList(value: string): ActiveAlias {
    return this.aliasList.find(
      alias => alias.id.toLowerCase() === value.toLowerCase()
    );
  }

  /**
* Ritorna l'alias se l'id inserito viene trovato in lista.
* E' case insensitive
* @param value id dell'alias da ricercare
*/
  private getFrontEndFromList(value: string): FrontEnd {
    return this.frontEndList.find(
      fe => fe.idfrontend.toLowerCase() === value.toLowerCase()
    );
  }

  /**
   * Apre il contenuto dell iframe in un nuovo tab.
   */
  public apriInNuovoTab(): void {
    const win = window.open(this.resultUrl, '_blank');
    win.focus();
  }

  /**
* Ritorna l'alias se l'id inserito viene trovato in lista.
* E' case insensitive
* @param value id dell'alias da ricercare
*/
  private getClientFromList(value: string): Client {
    return this.clientsList.find(
      client => client.idclient.toLowerCase() === value.toLowerCase()
    );
  }

  /**
 * Ritorna una lista di alias filtrata in base al valore ricevuto
 * @param value Valore da ricercare tra gli alias
 */
  private filterActiveAliases(value: string): ActiveAlias[] {
    const filterValue = value.toLowerCase();
    return this.aliasList.filter(
      alias => alias.id.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  /**
* Ritorna una lista di alias filtrata in base al valore ricevuto
* @param value Valore da ricercare tra gli alias
*/
  private filterFrontEnd(value: string): FrontEnd[] {
    const filterValue = value.toLowerCase();
    return this.frontEndList.filter(
      fe => fe.idfrontend.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  /**
* Ritorna una lista di alias filtrata in base al valore ricevuto
* @param value Valore da ricercare tra gli alias
*/
  private filterClients(value: string): Client[] {
    const filterValue = value.toLowerCase();
    return this.clientsList.filter(
      client => client.idclient.toLowerCase().indexOf(filterValue) >= 0
    );
  }
}
