import { Notification } from './../models/notification';
import { ConfigData } from 'app/app.config';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class NotificationsCenterService {

    public notifications = [];
    private notificationsSub = new Subject<any>();
    public notificationsSub$ = this.notificationsSub.asObservable();
    public currentPage: number = 0;
    private pageSize: number = 10;
    public unreadNotificationsNumber: number;

    constructor(private httpClient: HttpClient) { }


    public getNotifications(): Observable<Notification[]> {
        return this.httpClient.get<Notification[]>(`${ConfigData.apiUrl}/notification?page=${this.currentPage}&size=${this.pageSize}`);
    }
    public getNotificationsHistory(filters? : any): Observable<Notification[]> {
        let params = new HttpParams();
        params = !!filters.read
          ? params.append("read", filters.read)
          : params;
        params = !!filters.day
          ? params.append("day", filters.day || "")
          : params;
        params = !!filters.start
          ? params.append("start", this.getUnixValue(filters.start))
          : params;
        params = !!filters.end
          ? params.append("end", this.getUnixValue(filters.end))
          : params;
        params =  params.append("size", filters.size.toString())
        params =  params.append("page", filters.page.toString())
        return this.httpClient.get<Notification[]>(`${ConfigData.apiUrl}/notification/historical`, {
            params,
          });
    }

    public markNotificationRead(notification: Notification): Observable<any> {
        return this.httpClient.put<Notification>(`${ConfigData.apiUrl}/notification/${notification.idNotification}`, {});
    }

    // Aggiorno l'observable e l'array notifications
    public emitdata(data: any) {
        this.notifications = data;
        this.notificationsSub.next(data);
    }

    public refreshNotifications(): void {
        this.getNotifications().subscribe(list => {
            this.unreadNotificationsNumber = list.filter(x => !x.read).length;
            this.notifications = this.sortByDate(list);
            this.updateNotificationsHistory(this.notifications);
        })
    }

    updateNotificationsHistory(notifications) {
        // Update delle notifiche sul service
        this.emitdata(notifications);
    }
    sortByDate(list) {
        list.sort((a, b) => {
            if (a.creationDate < b.creationDate) {
                return -1;
            } else if (a.creationDate > b.creationDate) {
                return 1;
            } else {
                return 0;
            }
        })
        return list;
    }

    getUnixValue(date) {
        // Creo una copia senza modificare l'oggetto originale
        let gmtDate2 = JSON.parse(JSON.stringify(date));
        gmtDate2 = new Date(gmtDate2);
        gmtDate2.setDate(gmtDate2.getDate() + 1);
        gmtDate2 = gmtDate2.valueOf();
        return gmtDate2
    }
}